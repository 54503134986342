/*Compiled into css*/
.widget-clock {
  .digital {
    text-align: center;
  }
  .time {
    font-family: 'Syncopate', sans-serif;
    font-size: 2.5em;
    margin: 0;
  }
  .square {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
  .clock-face {
    stroke: #333;
    fill: white;
  }
  .minor {
    stroke: #999;
    stroke-width: 0.5;
  }
  .major {
    stroke: #333;
    stroke-width: 1;
  }
  .hour {
    stroke: #333;
  }
  .minute {
    stroke: #666;
  }
  .second,
  .second-counterweight {
    stroke: rgb(16, 118, 237);
  }
  .second-counterweight {
    stroke-width: 3;
  }
  &.dark {
    .clock-face {
      fill: #111;
    }
    .hour {
      stroke: #666;
    }
    .minute {
      stroke: #999;
    }
  }
}
/* end clock less */
